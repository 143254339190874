<template>
  <div>
    <b-overlay
      :show="isKycConfigLoading"
      spinner-variant="primary"
      variant="light"
      opacity="0.7"
      rounded="sm"
    >
      <b-card
        title="KYC Config"
      >
        <b-row>
          <b-col
            md="12"
            xl="4"
          >
            <v-select
              v-model="kycConfigType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="kycConfigOptions"
              :clearable="false"
              :searchable="false"
              class="w-100"
              :reduce="(val) => val.value"
              @input="kycConfigTypeChange"
            />
            <!-- <a :href="ddFormUrl" target="_blank" rel="noopener noreferrer">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                DD Form Template
              </b-button>
            </a> -->
          </b-col>
          <b-col
            md="12"
            xl="8"
          >
            <div class="upload-button-box">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="upload-button"
              >
                Upload
              </b-button>
              <input
                type="file"
                class="upload-file-input"
                @change="uploadFileChange(arguments[0])"
              >
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            md="12"
            xl="2"
            class="text-right mt-2"
            style="line-height: 38px"
          >
            Preview
          </b-col>
          <b-col
            md="12"
            xl="10"
          >
            <div id="container" />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-overlay
      :show="isKycReviewLoading"
      spinner-variant="primary"
      variant="light"
      opacity="0.7"
      rounded="sm"
    >
      <b-card class="revenue-report-list mt-2" title="KYC Review">
        <!-- Table -->
        <b-table
          ref="revenueReportListTable"
          class="mt-1"
          :items="fetchRevenueList"
          responsive
          :fields="revenueReportColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-link
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :to="{ name: 'reseller-info-detail', query:{reseller_id: data.item.reseller_id, from: 'kycreview' } }"
              target="_blank"
            >
              <b-button
                variant="primary"
              >
                Review{{ data.item.a }}
              </b-button>
            </b-link>
          </template>
        </b-table>
        <!-- Pagination -->
        <!-- <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
            >
              <span
                class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span>
              <span>
                <b-form-select
                  v-model="perPage"
                  :options="perPageOptions"
                  class="mx-1 sct-self"
                />
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div> -->
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import {
  defineComponent, reactive, computed, watch, toRefs, onMounted, getCurrentInstance,
} from '@vue/composition-api'
import {
  BCard,
  BCardBody,
  BTable,
  BButton,
  BRow,
  BCol,
  BPagination,
  BFormSelect,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { renderAsync } from 'docx-preview'
import store from '@/store'
import resellerStoreModule from '@/pagsmile/resellerStoreModule'
import { showToast } from '@/libs/tool'
import vSelect from 'vue-select'

const useKycConfig = proxy => {
  const state = reactive({
    isKycConfigLoading: false,
    ddFormUrl: '',
    kycConfigType: 'Individual',
    kycConfigOptions: [
      { label: 'Entity', value: 'Entity' },
      { label: 'Individual', value: 'Individual' },
    ],
  })
  const {
    ddFormUrl, isKycConfigLoading, kycConfigType, kycConfigOptions,
  } = toRefs(state)
  const changeUrlToStream = url => {
    if (!url) { return }
    store.dispatch('resellerKyc/resellerUrlToStream', { url }).then(res => {
      state.isKycConfigLoading = false
      const { data } = res
      renderAsync(data, document.getElementById('container'), null, {
        className: 'docx', // 默认和文档样式类的类名/前缀
        inWrapper: true, // 启用围绕文档内容渲染包装器
        ignoreWidth: false, // 禁止页面渲染宽度
        ignoreHeight: false, // 禁止页面渲染高度
        ignoreFonts: false, // 禁止字体渲染
        breakPages: true, // 在分页符上启用分页
        ignoreLastRenderedPageBreak: true, // 禁用lastRenderedPageBreak元素的分页
        experimental: false, // 启用实验性功能（制表符停止计算）
        trimXmlDeclaration: true, // 如果为真，xml声明将在解析之前从xml文档中删除
        debug: false, // 启用额外的日志记录
      })
    })
  }
  const downladDDFormTemplate = () => {
    state.isKycConfigLoading = true
    store.dispatch('resellerKyc/fetchResellerKycTemplate', { type: state.kycConfigType }).then(res => {
      const { code, msg, data } = res.data
      if (code === '10000') {
        state.ddFormUrl = data
        changeUrlToStream(data)
      } else {
        console.log(msg)
      }
    })
  }
  const uploadFileChange = event => {
    const file = event.currentTarget.files[0]
    // eslint-disable-next-line no-control-regex
    const regCN = /[^\u0000-\u00FF]/
    if (regCN.test(file.name)) {
      // 文件名称不能含有中文
      showToast(proxy, 'Warning', 'Only supports English file name')
      return
    }
    if (file.name.split('.').slice(-1)[0] !== 'docx') {
      showToast(proxy, 'Warning', 'Docx File Only')
      return
    }
    state.isKycConfigLoading = true
    store.dispatch('resellerKyc/uploadKycTemplate', { file, type: state.kycConfigType }).then(res => {
      const { code, msg } = res.data
      if (code === '10000') {
        downladDDFormTemplate()
      } else {
        showToast(proxy, 'Warning', `fail with ${msg}`)
      }
    })
  }
  const kycConfigTypeChange = value => {
    state.kycConfigType = value
    downladDDFormTemplate()
  }
  return {
    ddFormUrl, isKycConfigLoading, kycConfigType, kycConfigOptions, downladDDFormTemplate, uploadFileChange, kycConfigTypeChange,
  }
}
const useKYCReview = () => {
  const state = reactive({
    isKycReviewLoading: false,
    revenueReportColumns: [
      { label: 'Reseller Name', key: 'reseller_name' },
      { label: 'Reseller ID', key: 'reseller_id' },
      { label: 'Descp', key: 'descp' },
      { label: 'Submit Time', key: 'create_time' },
      { label: 'status', key: 'status' },
      { label: 'Action', key: 'action' },
    ],
    revenueReportListTable: '',
    currentPage: 1,
    total: 0,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })
  const {
    revenueReportColumns, revenueReportListTable, currentPage, total, perPage, perPageOptions, isKycReviewLoading,
  } = toRefs(state)
  const fetchRevenueList = (ctx, callback) => {
    state.isKycReviewLoading = true
    store.dispatch('resellerKyc/resellerKycList').then(res => {
      state.isKycReviewLoading = false
      const { code, msg, data } = res.data
      if (code === '10000') {
        callback(data)
        state.total = data.length
      } else {
        console.log(msg)
      }
    })
  }
  const dataMeta = computed(() => {
    const localItemsCount = state.revenueReportListTable
      ? state.revenueReportListTable.localItems.length
      : 0
    return {
      from: state.perPage * (state.currentPage - 1) + (localItemsCount ? 1 : 0),
      to: state.perPage * (state.currentPage - 1) + localItemsCount,
      of: state.total,
    }
  })
  watch([currentPage, perPage], () => {
    state.revenueReportListTable.refresh()
  })
  return {
    revenueReportColumns, revenueReportListTable, currentPage, total, perPage, perPageOptions, isKycReviewLoading, fetchRevenueList, dataMeta,
  }
}
export default defineComponent({
  components: {
    BCard,
    BCardBody,
    BTable,
    BButton,
    BRow,
    BCol,
    BFormSelect,
    BLink,
    BOverlay,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const RESELLER_KYC_STORE_MODULE_NAME = 'resellerKyc'
    if (!store.hasModule(RESELLER_KYC_STORE_MODULE_NAME)) {
      store.registerModule(RESELLER_KYC_STORE_MODULE_NAME, resellerStoreModule)
    }
    const { proxy } = getCurrentInstance()
    const {
      ddFormUrl, isKycConfigLoading, kycConfigType, kycConfigOptions, downladDDFormTemplate, uploadFileChange, kycConfigTypeChange,
    } = useKycConfig(proxy)
    const {
      revenueReportColumns, revenueReportListTable, currentPage, total, perPage, perPageOptions, isKycReviewLoading, fetchRevenueList, dataMeta,
    } = useKYCReview()
    onMounted(() => {
      downladDDFormTemplate()
    })
    return {
      revenueReportColumns,
      fetchRevenueList,

      revenueReportListTable,
      currentPage,
      total,
      perPage,
      perPageOptions,
      isKycReviewLoading,
      dataMeta,

      ddFormUrl,
      isKycConfigLoading,
      kycConfigType,
      kycConfigOptions,
      uploadFileChange,
      kycConfigTypeChange,
    }
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.upload-button-box {
  width: 100px;
  height: 38px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  ::v-deep .upload-button {
    position: relative;
    cursor: pointer;
  }
  .upload-file-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 38px;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
