import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // ********************************--KYC Management--**********************************
    // 获取模板
    fetchResellerKycTemplate(ctx, params = {}) {
      return new Promise((resolve, reject) => {
        axios.post(`/reseller/kyc/download-template?access_token=${useJwt.getToken()}&file_type=${params.type}`, {}).then(response => {
          resolve(response)
        }).catch(error => { reject(error) })
      })
    },
    // 上传模板
    uploadKycTemplate(ctx, params = {}) {
      return new Promise((resolve, reject) => {
        const formdata = new FormData()
        formdata.append('files', params.file)
        formdata.append('file_type', params.type)
        axios.post(`/reseller/kyc/update-template?access_token=${useJwt.getToken()}`, formdata, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => {
          resolve(response)
        }).catch(error => { reject(error) })
      })
    },
    // url转流
    resellerUrlToStream(ctx, params = {}) {
      return axios.post(`/reseller/kyc/url-to-stream?access_token=${useJwt.getToken()}&url=${params.url}`, {}, {
        responseType: 'blob',
      })
    },
    // kyc review
    resellerKycList(ctx, params = {}) {
      return axios.post(`/reseller/kyc/list?access_token=${useJwt.getToken()}`, params)
    },
    // ********************************--Reseller Management--**********************************
    // 列表页
    resellerManageList(ctx, params = {}) {
      return axios.post(`/reseller/list?access_token=${useJwt.getToken()}`, { uid: params.uid })
    },
    // ********************************--detail页--**********************************
    // ** kyc info **
    resellerKycDetail(ctx, params = {}) {
      return axios.get(`/reseller/kyc/detail?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`)
    },
    // 通过
    kycApprovalPass(ctx, params = {}) {
      return axios.post(`/reseller/kyc/approval-pass?access_token=${useJwt.getToken()}`, { id: params.id })
    },
    // 拒绝
    kycApprovalReject(ctx, params = {}) {
      return axios.post(`/reseller/kyc/approval-reject?access_token=${useJwt.getToken()}`, { id: params.id })
    },
    // ** basic info **
    fetchBasicInfo(ctx, params = {}) {
      return axios.post(`/reseller/getById?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`)
    },
    // ** buyin-rate-config ** list
    fetchBuyinRateConfig(ctx, params = {}) {
      return axios.post(`/reseller/buyin/latest?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`, params)
    },
    // buyin-rate-config add
    addBuyinRateConfig(ctx, params = {}) {
      return axios.put(`/reseller/buyin/add?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`, params)
    },
    // buyin-rate-config update
    updateBuyinRateConfig(ctx, params = {}) {
      return axios.post(`/reseller/buyin/update?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`, params)
    },
    // buyin-rate-config history
    buyinRateConfigHistory(ctx, params = {}) {
      return axios.post(`/reseller/buyin/list-history?access_token=${useJwt.getToken()}&reseller_id=${params.id}`, {
        page_num: params.page_num,
        page_size: params.page_size,
      })
    },
    // buyin-rate-config status update
    buyinRateConfigStatusUpdate(ctx, params = {}) {
      return axios.post(`/reseller/buyin/toggle-buyin-status?access_token=${useJwt.getToken()}&id=${params.id}`)
    },
    // add/edit 下拉选项
    fetchInitialCurrencyOptions(ctx, params = {}) {
      return axios.post(`/reseller/revenue/currency-options?access_token=${useJwt.getToken()}&revenueId=${params.revenueId}`, {})
    },
    fetchResellerBuyinOptions(ctx, params = {}) {
      return axios.post(`/reseller/buyin/options?access_token=${useJwt.getToken()}`, params)
    },
    // ** Settlement Config ** list
    resellerRevenueList(ctx, params = {}) {
      return axios.post(`/reseller/revenue/list-revenue?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`, params)
    },
    toggleRevenueStatus(ctx, params = {}) {
      return axios.post(`/reseller/revenue/toggle-revenue-status?access_token=${useJwt.getToken()}&id=${params.id}&reseller_id=${params.reseller_id}`)
    },
    // 覆盖报表
    revenueOverrideReport(ctx, params = {}) {
      return new Promise((resolve, reject) => {
        const formdata = new FormData()
        formdata.append('report_file', params.report_file)
        axios.post(`/reseller/revenue/override-report?access_token=${useJwt.getToken()}&revenueId=${params.revenueId}`, formdata, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => {
          resolve(response)
        }).catch(error => { reject(error) })
      })
    },
    // 展示分润app
    resellerRevenueListApp(ctx, params = {}) {
      return axios.post(`/reseller/revenue/list?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`, params)
    },
    // check 费率
    resellerCheckFeeRates(ctx, params = {}) {
      return axios.post(`/reseller/revenue/list-revenue-apps?access_token=${useJwt.getToken()}&revenueId=${params.revenueId}`, {})
    },
    // check 汇率
    resellerCheckExchangeRates(ctx, params = {}) {
      return axios.post(`/reseller/revenue/list-revenue-currency?access_token=${useJwt.getToken()}&revenueId=${params.revenueId}`, {})
    },
    // 确认第一步
    resellerRevenueConfirmStep1(ctx, params = {}) {
      return axios.put(`/reseller/revenue/confirm-step1?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}&formula=${params.formula}`, params.tableItems)
    },
    // 确认第二步
    resellerRevenueConfirmStep2(ctx, params = {}) {
      return axios.post(`/reseller/revenue/confirm-step2?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`, params.tableItems)
    },
    // 确认第三步
    resellerRevenueConfirmStep3(ctx, params = {}) {
      return axios.post(`/reseller/revenue/confirm-step3?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}`, params.tableItems)
    },
    // 最终确认
    resellerRevenueConfirm(ctx, params = {}) {
      return axios.post(`/reseller/revenue/confirm?access_token=${useJwt.getToken()}&reseller_id=${params.reseller_id}&descp=${params.descp}&revenueId=${params.revenueId}`, {})
    },
  },
}
